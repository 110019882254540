
import { Media } from '../models/Media';
import { Location } from '../models/Location';

import axios, { AxiosRequestConfig } from "axios";
import { config } from '../env';

import { Geolocation } from '@capacitor/geolocation';
import { Storage } from '@capacitor/storage';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';

const dataUrl = '/assets/data/data.json';

const HAS_LOGGED_IN = 'hasLoggedIn';
const HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
const USERNAME = 'username';
const DARKMODE = 'darkMode';

const headers: AxiosRequestConfig = {
  headers: {
    'accept': 'text/plain',
    'Content-Type': 'application/json; charset=UTF-8'
  }
};
const postDeviceInfo = async () => {
  const deviceId: DeviceId = await Device.getId();
  const deviceInfo: DeviceInfo = await Device.getInfo();
  console.log('postDeviceInfo', deviceId, deviceInfo);
  let now = new Date();
  let dernierevue = now.toLocaleString();
  // POST TO API
  const apipostdevicedata = JSON.stringify({
    'id': deviceId.uuid,
    'dernierevue': dernierevue,
    'uuid': deviceId.uuid,
    'version': '4.2.22.1',
    'createdBy': 'pzdetect',
    'partitionKey': 'pz',
    'message': deviceInfo.model,
    'platform': deviceInfo.platform,
    'manufacturer': deviceInfo.manufacturer,
    'webViewVersion': deviceInfo.webViewVersion,
  });
  axios.post(`${config.apiUrl}api/terminal`, apipostdevicedata, headers).then((response) => {
    console.log(`2 axios status: ${response.status}`);
    if (response.status === 200) {
      console.log(`2 axios mise à jour réussie: ${JSON.stringify(response)}`);
    } else {
      console.log(`2 axios erreur mise à jour: ${response}`);
    }
  }).catch(error => {
    console.error(`2 axios erreur : ${error}`);
  });
  axios.put(`${config.apiUrl}api/terminal`, apipostdevicedata, headers).then((response) => {
    console.log(`2 axios status: ${response.status}`);
    if (response.status === 200) {
      console.log(`2 axios mise à jour réussie: ${JSON.stringify(response)}`);
    } else {
      console.log(`2 axios erreur mise à jour: ${response}`);
    }
  }).catch(error => {
    console.error(`2 axios erreur : ${error}`);
  });
}
export const getData = async () => {
  console.log('getData');
  await postDeviceInfo();
   //,fetch(`${config.apiUrl}api/geoloc`)
  const response = await Promise.all([
    fetch(dataUrl)
    ]);
  const responseData = await response[0].json();
  const medias = responseData.medias as Media[];
  console.log(`getData 0 axios status: ${response[0].status}`);
  const data = {
    locations: geolocList,
    medias
  }
  return data;
}

let geolocList: Location[] = [];

const getDevicePosition = async () => {
  console.log('getDevicePosition');
  geolocList = [];
  const deviceId: DeviceId = await Device.getId();
  const deviceInfo: DeviceInfo = await Device.getInfo();
  console.log('getDevice', deviceId, deviceInfo);
  let now = new Date();
  //.moment().format("YYYY.MM.DD.HH.mm");
  let ts = now.getFullYear() + '.' + (now.getMonth() + 1) + '.' + now.getDate()
    + '.' + now.getHours() + '.' + now.getMinutes();
    let lastSeen = now.toISOString();
    let dernierevue = now.toLocaleString();
    console.log(`dernierevue: ${dernierevue}`);
  await Geolocation.getCurrentPosition().then(pos => {
    // first add adm
    let lato = pos.coords.latitude;
    let lngo = pos.coords.longitude;
    console.log('getDevicePosition ' + lato + ' ' + lngo);
    geolocList.push({
      playerId: 'gps',
      id: 0,
      lastSeen: lastSeen,
      lat: lato,
      lng: lngo,
      name: 'Moi',
      createdBy: 'Moi',
      partitionKey: 'adm',
      dernierevue: dernierevue,
      timestamp: ts,
      version: '4.2.22.1',
      uuid: deviceId.uuid,
      markerColor: 'purple',
    })

    // POST TO API
    const apipostgeolocdata = JSON.stringify({
      'id': deviceId.uuid,
      'timestamp': ts,
      'lat': lato,
      'lng': lngo,
      'name': 'Centre',
      'uuid': deviceId.uuid,
      'version': '4.2.22.1',
      "createdBy": username,
      'partitionKey': 'adm',
      "lastSeen": lastSeen,
      "message": "post ionic",
      "lastError": ts,
    });
    axios.post(`${config.apiUrl}api/geoloc`, apipostgeolocdata, headers).then((response) => {
      //console.log(`2 axios status: ${response.status}`);
      if (response.status === 200) {
        //console.log(`2 axios mise à jour réussie: ${JSON.stringify(response)}`);
      } else {
        console.log(`2 axios erreur mise à jour: ${response}`);
      }
    }).catch(error => {
      console.error(`2 axios erreur : ${error}`);
    });
    // PUT TO API
    /*const apiputdata = JSON.stringify({
      'id': deviceId.uuid,
      'timestamp': ts,
      'lat': lato,
      'lng': lngo,
      'name': geolocList[0].name,
      'uuid': deviceId.uuid,
      'version': '4.2.22.1',
      "createdBy": username,
      'partitionKey': 'pzadm',
      "lastSeen": lastSeen,
      "message": "put ionic",
      "lastError": ts,
    });
    axios.put(`${config.apiUrl}api/geoloc`, apiputdata, headers).then((response) => {
      //console.log(`3 axios status: ${response.status}`);
      if (response.status === 200) {
        //console.log(`3 axios mise à jour réussie: ${JSON.stringify(response)}`);
      } else {
        console.log(`3 axios erreur mise à jour: ${response}`);
      }
    }).catch(error => {
      console.error(`3 axios erreur : ${error}`);
    });*/
  })
}

export const getLocationData = async () => {
  console.log(`getLocationData`);
  let zIndex: number = 0;
  await getDevicePosition();
  //console.log(`getDevicePosition`);
  const response = await Promise.all([
    fetch(`${config.apiUrl}api/nearby`)]);// for cosmos  /api/geoloc`)]);
  console.log(`getLocationData 0 axios status: ${response[0].status}`);
  const locs = await response[0].json();
  console.log(`getLocationData 1 axios locs: ${JSON.stringify(locs)}`);
  const locations = locs as Location[]; // 20220707 was .geoloc
  //console.log(`getLocationData 1.5 axios locations: ${JSON.stringify(locations)}`);
  for (let item in locations) {
    //console.log(`getLocationData 2 itaime l: ${locations.length} z: ${zIndex}`);// uuid ${locations[item].name} uuid ${JSON.stringify(locations[item])}`);
    zIndex++;
    let id = zIndex;
    let createdBy = locations[item].createdBy;
    let name = locations[item].name ? locations[item].name : 'Inconnu';
    let color: string = createdBy === 'pzd' ? 'yellow' : 'blue';
    if (createdBy === 'pzdetect') color = 'pink';
    if (createdBy === 'adm') color = 'red';
    const locDate = new Date(locations[item].lastSeen);
    let dernierevue = locDate.toLocaleString();
    const diff = Math.abs(new Date().getTime() - locDate.getTime()) / 1000;

    console.log(`diff: ${diff} seconds`);

    // 20211224 TODO variable
    // done in backend if (diff < 60000) {
      geolocList.push({
        id: id,
        playerId: locations[item].playerId,
        lastSeen: locations[item].lastSeen,
        lat: locations[item].lat,
        lng: locations[item].lng,
        name: name,
        createdBy: createdBy,
        dernierevue: dernierevue,
        timestamp: locations[item].timestamp,
        version: locations[item].version,
        uuid: locations[item].uuid,
        markerColor: color
      });
    //}

  }
  console.log(`taille liste api ${geolocList.length}`);
  const data = {
    locations: geolocList
  }
  return data;
}

let username = 'adm';
export const getUserData = async () => {
  const response = await Promise.all([
    Storage.get({ key: HAS_LOGGED_IN }),
    Storage.get({ key: HAS_SEEN_TUTORIAL }),
    Storage.get({ key: DARKMODE }),
    Storage.get({ key: USERNAME })]);
  const isLoggedin = await response[0].value === 'true';
  const hasSeenTutorial = await response[1].value === 'true';
  const darkMode = await response[2].value === 'true';
  const username = await response[3].value || undefined;
  const data = {
    isLoggedin,
    hasSeenTutorial,
    darkMode,
    username
  }
  return data;
}

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Storage.set({ key: HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) });
}

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  await Storage.set({ key: HAS_SEEN_TUTORIAL, value: JSON.stringify(hasSeenTutorial) });
}

export const setDarkModeData = async (darkMode: boolean) => {
  await Storage.set({ key: DARKMODE, value: JSON.stringify(darkMode) });
}

export const setUsernameData = async (username?: string) => {
  if (!username) {
    await Storage.remove({ key: USERNAME });
  } else {
    await Storage.set({ key: USERNAME, value: username });
  }
}
