import React from "react";
import { IonContent, IonPage } from "@ionic/react";

const Home: React.FC = () => {
  return (
    <IonPage id="accueil-page">
      <IonContent className="background" fullscreen={true} scrollEvents={true}>
        <div className="top-header">
          <img src="assets/img/appicon.png" alt="logo" id="header-img" />
        </div>
        <p>Detect 4.2.22.1</p>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Home);
