import { SessionsActions } from './sessions.actions';
import { GlobalState } from './global.state';

export const sessionsReducer = (state: GlobalState, action: SessionsActions): GlobalState => {
  switch (action.type) {
    case 'set-loading': {
      return { ...state, loading: action.isLoading };
    }
    case 'set-data': {
      return { ...state, ...action.data };
    }
    case 'set-location-data': {
      return { ...state, ...action.data };
    }
    case 'set-menu-enabled': {
      return { ...state, menuEnabled: action.menuEnabled };
    }
  }
}
