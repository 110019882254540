// NEW SLV amultitenantpersonal 7e54960f-6a30-46f0-bc97-00b5ac7cbc2b tenant ea6f633d-bdc6-4c53-a323-3ff6416fd2d4
// dernier avant slv 8a99f36c-ce3a-45ab-ba99-744dc105bb59
// OK 20211224 PerfLinkMTA 4e29a821-1303-4a6c-abbe-2ac3372894c3 tenant 4cd94b03-4d8a-44c9-8815-1fb4c380614a
// perflinkspamultitenantpersonal onmic tenant 8a460b3e-2541-4e43-ac51-8f817662cf6e 8a99f36c-ce3a-45ab-ba99-744dc105bb59
// perflinkmultitenantpersonal onmic tenant 8a460b3e-2541-4e43-ac51-8f817662cf6e b339384c-431c-4956-ab89-b2b44c6b6a6d
export const config = {
  redirectUri: "http://localhost:3000",
  apiUrl: "https://prodosedetect.com/",
  serverUrl: 'https://prodosedetect.com/'
};

export const constants = {
  bingMapsApiKey: '<your bing maps key here>'
};
