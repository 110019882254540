import { getData, getLocationData } from '../dataApi';
import { ActionType } from '../../util/types';
import { GlobalState } from './global.state';

export const loadData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getData();
  const locationData = await getLocationData();
  dispatch(setData(data));
  dispatch(setLocationData(locationData));
  dispatch(setLoading(false));
}
export const updateLocationData = () => async (dispatch: React.Dispatch<any>) => {
  const locationData = await getLocationData();
  dispatch(setLocationData(locationData));
}

export const setLocationData = (data: Partial<GlobalState>) => ({
  type: 'set-location-data',
  data
} as const);

export const setLoading = (isLoading: boolean) => ({
  type: 'set-loading',
  isLoading
} as const);

export const setData = (data: Partial<GlobalState>) => ({
  type: 'set-data',
  data
} as const);

export const addFavorite = (sessionId: number) => ({
  type: 'add-favorite',
  sessionId
} as const);

export const removeFavorite = (sessionId: number) => ({
  type: 'remove-favorite',
  sessionId
} as const);

export const setMenuEnabled = (menuEnabled: boolean) => ({
  type: 'set-menu-enabled',
  menuEnabled
} as const);

export type SessionsActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setLocationData>
  | ActionType<typeof setData>
  | ActionType<typeof setMenuEnabled>
